<template>


    <!--
    https://apihost.ru/voice

  1—2—3—В данном видеоролике демонстрируется алгоритм записи на прием к врачу через интернет--
  Если вы не успеваете считать информацию, нажмите клавишу пробел и воспроизведение видео остановится-- для продолжения  снова нажмите пробел--
  На начальной странице сайта нажмите кнопку  -Записаться к врачу--
  Из выпадающего списка выберите вариант приема--
  Затем категорию приема--
  Выберите группу специалистов--
  Врача--
  Дату приема-
  и удобное время приема--
  Введите ваши данные и нажмите кнопку Записаться к врачу--
  Если ошибок в ваших данных не найдено вы увидите на экране выбранный вами талон посещения --
  Поздравляем! - Вы записались на прием

  1--2--3--В этом видеоролике демонстрируется алгоритм отмены талона на прием к врачу--
  Если вы имеете талон посещения но не можете прийти -- вам необходимо отменить этот прием через сайт записи к врачу через интернет--
  На начальной странице сайта нажмите кнопку  Дополнительно -
  и выберите  Отмена приема--
  Введите ваши данные  - день рождения и номер полиса--
  Затем нажмите кнопку Далее--
  Нажмите кнопку Удалить  в талоне на прием--
  Подтвердите действие--
  Поздравляем! - Вы отменили запись на прием--

    -->
    <v-container>

        <div id="videohelp-top" class="text-center mt-16 mb-10">
        <h2>{{caption}}</h2>
        </div>


        <v-row class="text-center ml-6 mr-6 mb-10">
            Здесь вы можете посмотреть видеоинструкции, которые помогут вам увереннее использовать возможности нашего сайта.
        </v-row>


        <div class="video text-center font-weight-light text-md-h5 pt-6 "
        >
      <span >
        Алгоритм записи к врачу через интернет
      </span>
        </div>


        <div class="video mt-6 mb-10 ">
            <video width="100%" class="video pa-6"controls>
                <!--source :src="require(`/static/talonnew.mp4`)" type="video/mp4"-->
                <source v-if="$vuetify.breakpoint.mobile" :src="require('@/assets/public/talon74newsmall.mp4')" type="video/mp4">
                <source v-else :src="require('@/assets/public/talon74new.mp4')" type="video/mp4">
                Ваша программа не поддерживает воспроизведение данного видео.
            </video>
        </div>

        <div class="video text-center font-weight-light text-md-h5 pt-6 "
        >
      <span >
        Алгоритм отмены талона на прием
      </span>
        </div>


        <div class="video mt-6 mb-10">
            <video width="100%" class="video pa-6"controls>
                <source v-if="$vuetify.breakpoint.mobile" :src="require('@/assets/public/talon74delsmall.mp4')" type="video/mp4">
                <source v-else :src="require('@/assets/public/talon74del.mp4')" type="video/mp4">
                Ваша программа не поддерживает воспроизведение данного видео.
            </video>
        </div>


    </v-container>



</template>

<script>

    export default {
        name: "videohelp",
        data() {
            return {
                caption: 'Видеоинструкции',
            }
        },
        computed: {
            STYLE_BW() {
                return thisSTYLE_BW(this);
            }
        }
        /*,
              components: {
                VideoPlayer
              }*/

    }
</script>

<style scoped>

</style>
